import { useState, useEffect, useCallback } from "react"
import { CHARGEBEE_KEY, CHARGEBEE_SITE } from "./config"
import { graphql, useStaticQuery } from "gatsby"

export interface Fetchable<T> {
  error: boolean | null | unknown
  data: T | null
  loading: boolean
  reload: () => void
}

function useFetch<T>(
  url: string,
  fetchParams?: RequestInit,
  processData?: (response: Response) => Promise<T>,
  onError?: (error: unknown) => void
): Fetchable<T> {
  const [data, setData] = useState<T | null>(null)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<boolean | null | unknown>(false)
  const [reloadTimes, setReload] = useState(0)
  const reload = useCallback(
    () => setReload(reloadTimes + 1),
    [setReload, reloadTimes]
  )

  useEffect(() => {
    async function fetchUrl() {
      try {
        let response = await fetch(url, fetchParams)
        let data: T
        if (processData) {
          data = await processData(response)
        } else {
          data = (await response.json()) as T
        }

        setData(data)
        setLoading(false)
        setError(null)
      } catch (error) {
        setError(error)
        setLoading(false)

        if (onError) {
          onError(error)
        }
      }
    }

    setLoading(true)
    fetchUrl()
  }, [url, fetchParams, processData, onError, reloadTimes])
  return { data, loading, error, reload }
}

// from: https://usehooks.com/useScript/
// Hook
let cachedScripts: Array<string> = []
function useScript(src: string) {
  // Keeping track of script loaded and error state
  const [state, setState] = useState({
    loaded: false,
    error: false,
  })

  useEffect(
    () => {
      // If cachedScripts array already includes src that means another instance ...
      // ... of this hook already loaded this script, so no need to load again.
      if (cachedScripts.includes(src)) {
        setState({
          loaded: true,
          error: false,
        })
      } else {
        cachedScripts.push(src)

        // Create script
        let script = document.createElement("script")
        script.src = src
        script.async = true

        // Script event listener callbacks for load and error
        const onScriptLoad = () => {
          setState({
            loaded: true,
            error: false,
          })
        }

        const onScriptError = () => {
          // Remove from cachedScripts we can try loading again
          const index = cachedScripts.indexOf(src)
          if (index >= 0) cachedScripts.splice(index, 1)
          script.remove()

          setState({
            loaded: true,
            error: true,
          })
        }

        script.addEventListener("load", onScriptLoad)
        script.addEventListener("error", onScriptError)

        // Add script to document body
        document.body.appendChild(script)

        // Remove event listeners on cleanup
        return () => {
          script.removeEventListener("load", onScriptLoad)
          script.removeEventListener("error", onScriptError)
        }
      }
    },
    [src] // Only re-run effect if script src changes
  )

  return [state.loaded, state.error]
}

function useChargebee() {
  let [chargebeeReady, setChargebeeReady] = useState(false)
  let [chargebeeLoaded, chargebeeError] = useScript(
    "https://js.chargebee.com/v2/chargebee.js"
  )
 
  useEffect(() => {
    if (chargebeeLoaded && !chargebeeError && !window.Chargebee.inited) {
      window.Chargebee.init({
        site: CHARGEBEE_SITE,
        publishableKey: CHARGEBEE_KEY,
      })
      setChargebeeReady(true)
    }
    if (window.Chargebee && window.Chargebee.inited) {
      setChargebeeReady(true)
    }
  }, [chargebeeLoaded])

  return chargebeeReady
}

//usehooks.com/useWindowSize/
function useWindowSize() {
  const isClient = typeof window === "object"

  let getSize = useCallback(
    function getSize() {
      return {
        width: isClient ? window.innerWidth : undefined,
        height: isClient ? window.innerHeight : undefined,
      }
    },
    [isClient]
  )

  const [windowSize, setWindowSize] = useState(getSize)

  useEffect(() => {
    if (!isClient) {
      return
    }

    function handleResize() {
      setWindowSize(getSize())
    }

    window.addEventListener("resize", handleResize)
    return () => window.removeEventListener("resize", handleResize)
  }, [isClient, getSize]) // Empty array ensures that effect is only run on mount and unmount

  return windowSize
}

function useSiteMetadata() {
  let query = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          isWaitingListMode
          isServiceOutageMode
        }
      }
    }
  `)

  return query?.site?.siteMetadata
}

export {
  useFetch,
  useChargebee,
  useScript,
  useWindowSize,
  useSiteMetadata,
}
