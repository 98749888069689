export let CHARGEBEE_SITE =
  process.env.GATSBY_CHARGEBEE_SITE ?? "purplecowinternet"
export let CHARGEBEE_KEY =
  process.env.GATSBY_CHARGEBEE_API_KEY ?? "no-key";
export let ADDON_IMAGE_BUCKET_URL =
  process.env.GATSBY_ADDON_IMAGE_BUCKET_URL ?? "no-bucket-url";
export let lambdaUrl =
  process.env.GATSBY_LAMBDA ?? "https://api.purplecowinternet.com/";
export let FACEBOOK_PIXEL_ID = "3736525736473002"
export let GATSBY_CART_KEY = process.env.GATSBY_CART_KEY ?? "no-cart-key";
export let GATSBY_CART_IV = process.env.GATSBY_CART_IV ?? "no-cart-iv";
export let productCatalogS3Bucket = "https://product-catalog-storage.s3.ca-central-1.amazonaws.com/";