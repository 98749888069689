import React, { useCallback, useEffect, useState } from "react";

import sendButton from "../../../../static/images/send-btn.png";
import { useScript } from "../../../hooks";
import { Address } from "../../../addressValidator";
import { formatAddressWithApartmentNumber, removePrefix } from "../../../utils";
import { defaultFormData } from "../../../customfunction";
import { Link } from "gatsby";
const ServiceAddress = (props: any) => {
    const { title } = props;
    const [IsReady, setIsReady] = useState(false);
    const [Isaddress, setIsaddress] = useState(false);
    const [formData, setformData] = useState(defaultFormData());
    const [loaded] = useScript(
        "https://ws1.postescanada-canadapost.ca/js/addresscomplete-2.30.min.js"
    )
    /*
    *Address Mapping
    */
    let onFormStateChanged = useCallback((newFormState: any) => {
        setformData((formData) => ({ ...formData, ...newFormState }))
    }, []);
    let addressChange: any = useCallback(
        (shipping_address: any) => onFormStateChanged({ shipping_address }),
        [onFormStateChanged]
    )
    useEffect(() => {
        let type = window?.pca?.fieldMode?.DEFAULT;
        if (loaded && typeof type !== "undefined" && typeof type !== "undefined") {
            //setformData({ ...formData, shipping_address: {} })
            let fields = [
                {
                    element: "line1",
                    field: "Line1",
                    mode: window.pca.fieldMode.DEFAULT,
                },
                {
                    element: "line11",
                    field: "Line1",
                    mode: window.pca.fieldMode.DEFAULT,
                },
                {
                    element: "apartment",
                    field: "BuildingNumber",
                    mode: window.pca.fieldMode.POPULATE,
                }
            ]
            let options = {
                key: "MM49-DD25-FR41-WY59",
                bar: { showCountry: false, showLogo: true, logoLink: false },
            }
            let control = new window.pca.Address(fields, options)
            control.listen("populate", (a: Address) => {
                addressChange(a);
            })
            setIsReady(true)
            return () => {
                control.destroy()
            }
        }
    }, [loaded, addressChange]);

    let value: any = formData?.shipping_address;
    let formattedAddress: any = value
        ? formatAddressWithApartmentNumber(value)
        : ({} as Address)
    const checkAddress = () => {
        let values: any = formData?.shipping_address;
        setIsaddress(false);
        if (values?.Line1) {
            localStorage.setItem('address_data', JSON.stringify(formData));
            let line2address:any=document.getElementById('line2_address');
            if(line2address){
                line2address.value=formattedAddress?.SubBuilding || ""
            }
            setIsaddress(true);
        }
    }
    useEffect(() => {
        checkAddress();
    }, [formData])
    useEffect(()=>{
        localStorage.removeItem('address_data');
    },[])
    return (
        <>
            <link
                rel="stylesheet"
                type="text/css"
                href="https://ws1.postescanada-canadapost.ca/css/addresscomplete-2.30.min.css?key=bw11-fu43-na37-by38"
            />
        </>
    )
}

export default ServiceAddress
